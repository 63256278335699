import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {NetworkConnectionService} from '../services/network-connection.service';

export const offlineInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  const networkConnection = inject(NetworkConnectionService);
  if (networkConnection.connectionState && !networkConnection.connectionState.hasNetworkConnection) {
    throw new Error('Offline Mode')
  } else {
   return next(req);
  }
}
