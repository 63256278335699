import { Injectable } from '@angular/core';
import { MsalService as MSAL } from '@azure/msal-angular';
import { GlobalConstants } from '../globals';

@Injectable({
  providedIn: 'root',
})
// This service is adapted from
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/src/msal.service.ts
// (because the module there doesn't work in a PROD build)

export class MsalService {
  public user: any;

  constructor(
    private authService: MSAL,
  ) {
  }

  public logout(): void {
    this.user = null;
    this.authService.logout();
  }

  async login() {
    const result = await this.authService.loginPopup({ scopes: GlobalConstants.MicrosoftSSOConfig.consentScopes }).toPromise();
    return this.authService.acquireTokenSilent({ scopes: GlobalConstants.MicrosoftSSOConfig.consentScopes }).toPromise().catch(error => {
      // LoggerService.Error('Microsoft SSO AcquireTokenSilent Error: ', error);
      return this.authService.acquireTokenPopup({ scopes: GlobalConstants.MicrosoftSSOConfig.consentScopes }).toPromise();
    }).then(res => {
      if (res.accessToken.length > 0) {
        const email = result.account.username.indexOf('@') >= 0 ? result.account.username : '';
        return { token: res.accessToken, email };
      }
      return ({});
    }).catch((error2) => {
      // LoggerService.Error('Microsoft SSO AcquireTokenPopup Error: ', error2);
      return error2;
    });
  }
}
