export enum LicenseType {
  UniversalScreener = 1,
  MVRC = 2,
  FLRTOnly = 3,
  TeacherCompanion = 4,
  Dyslexia = 5,
  MindPlayLiteracy = 6,
  ComprehensiveReadingCourse = 7,
  Mathematics = 8,
  DyslexiaScreener = 9,
  MindPlayLiteracyPlus = 10,
  StudioUSA = 11,
  LightSailPremiumVideoAdder = 12,
  StudioInternational = 13
}
