import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AuthActions} from '../store/auth/auth.actions';
import {inject} from '@angular/core';
import {Store} from '@ngrx/store';

export const errorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  const store = inject(Store);
  return next(req).pipe(
    catchError(err => {
      if (err.status === 401 && !err.url.includes('api/Messages')) {
        store.dispatch(AuthActions.logout());
      }
      return throwError(err);
    }),
  );
}
