import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppStateService} from '../app.service';
import {AppStateConstants, GlobalConstants} from '../globals';
import {LoggerService} from '../services/logger.service';
import {ApiClientService} from './api-client.service';

@Injectable({
    providedIn: 'root'
})
export class Gg4lAuthService {

    popupWindow: Window;
    popupTimer;
    redirectUrl: string;
    private haveAuthenticated = false;

    constructor(private _http: HttpClient,
                private _appStateService: AppStateService,
                private _apiClient: ApiClientService) {
    }

    authenticate(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.redirectUrl = window.location.origin + window.location.pathname + 'index.html?gg4lAuth';
            this.popupWindow = window.open('https://sso.gg4l.com/oauth/auth?response_type=code&client_id='
                + GlobalConstants.gg4lClientId
                + '&redirect_uri=' + this.redirectUrl, 'Window', 'width=500,height=600,location=0,status=0');

            this.popupTimer = setInterval(() => {
                if (this.popupWindow.closed) {
                    clearInterval(this.popupTimer);
                    reject('GG4L authentication failed.');
                } else {
                    let href: string;
                    try {
                        href = this.popupWindow.location.href;
                    } catch (e) {
                        // Don't do anything as accessing location from inter browser windows returns exception if the urls are different.
                    }
                    if (href != null) {
                        const re = /code=(.*)/;
                        const match = re.exec(href);
                        if (match) {
                            this.haveAuthenticated = true;
                            clearInterval(this.popupTimer);
                            this.popupWindow.close();
                            const stringToParse = href.substr(match.index);
                            const parsed = this.parse(stringToParse);
                            this.exchangeToken(parsed.code, this.redirectUrl).then(token => {
                                resolve(token);
                            }).catch(err => {
                                reject(err);
                            });
                        }
                    }
                }
            }, 100);
        });
    }

    private exchangeToken(code, redirectUri): Promise<string> {
        const postUrl = `api/GlobalGridForLearningAuth/OAuth/Tokens`;
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/json');
        return new Promise<string>((resolve, reject) => {
            this._http.post(this._appStateService.get(AppStateConstants.lightSailServerURL) + postUrl, JSON.stringify({code, redirectUri}),
                {headers: headers, observe: 'body', responseType: 'text'}).toPromise().then((response) => {
                resolve(response);
            }).catch(error => {
                LoggerService.Error('GG4L Token validation Error: ', error);
                reject('GG4L authentication failed.');
            });
        });
    }

    private parse(str) {
        if (typeof str !== 'string') {
            return {};
        }

        str = str.trim().replace(/^(\?|#|&)/, '');

        if (!str) {
            return {};
        }

        return str.split('&').reduce(function (ret, param) {
            const parts = param.replace(/\+/g, ' ').split('=');
            let key = parts.shift();
            let val = parts.length > 0 ? parts.join('=') : undefined;

            key = decodeURIComponent(key);
            val = val === undefined ? null : decodeURIComponent(val);

            if (!ret.hasOwnProperty(key)) {
                ret[key] = val;
            } else if (Array.isArray(ret[key])) {
                ret[key].push(val);
            } else {
                ret[key] = [ret[key], val];
            }

            return ret;
        }, {});
    }
}
