import {inject, Injectable, isDevMode} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ENV_CONFIG} from '../../environment.config';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CleverService {

  popupWindow: Window;
  popupTimer;
  private haveAuthenticated = false;
  cleverClientId = 'b6e429f80c2ed25322e7';
  private environmentConfig = inject(ENV_CONFIG);

  constructor(private _http: HttpClient) {
  }

  authenticate(): Observable<string> {
    return new Observable(observer => {
      const redirectUrl = `${window.location.origin}/clever`;
      this.popupWindow = window.open(
        `https://clever.com/oauth/authorize?response_type=code&client_id=${this.cleverClientId}&redirect_uri=${redirectUrl}`,
        'Window',
        'width=500,height=600,location=0,status=0');

      this.popupTimer = setInterval(() => {
        if (this.popupWindow.closed) {
          clearInterval(this.popupTimer);
          observer.error('Clever authentication failed.');
        } else {
          let href: string;
          try {
            href = this.popupWindow.location.href;
          } catch (e) {
            // Don't do anything as accessing location from inter browser windows returns exception if the urls are different.
          }
          if (href != null) {
            const re = /code=(.*)/;
            const match = re.exec(href);
            if (match) {
              this.haveAuthenticated = true;
              clearInterval(this.popupTimer);
              this.popupWindow.close();
              console.log('url: ' + href);
              const stringToParse = href.substr(match.index);
              console.log('clever string to parse: ' + stringToParse);
              const parsed = this.parse(stringToParse);
              console.log('clever parsed string: ');
              console.log(parsed);
              this.exchangeToken(parsed.code)
                .subscribe({
                  next: (clever_token) => {
                  observer.next(clever_token);
                  observer.complete();
                },
                error: (err) => observer.error(err)
            });
            }
          }
        }
      }, 100);
    });
  }

  exchangeToken(code: string): Observable<string> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json');
    const creds = {
      'credentials': {
        grant_type: 'authorization_code',
        redirect_uri: `${window.location.origin}/clever`,
        code: code
      }, 'dev': isDevMode()
    };

    return this._http.post(
      `${this.environmentConfig.idsApiUrl}GetCleverToken`,
      JSON.stringify(creds),
      {
        headers: headers,
        observe: 'body'
      }
    ).pipe(
      map((res: {access_token: string}) => res.access_token),
    );
  }

  private parse(str) {
    if (typeof str !== 'string') {
      return {};
    }

    str = str.trim().replace(/^(\?|#|&)/, '');

    if (!str) {
      return {};
    }

    return str.split('&').reduce(function (ret, param) {
      const parts = param.replace(/\+/g, ' ').split('=');
      let key = parts.shift();
      let val = parts.length > 0 ? parts.join('=') : undefined;

      key = decodeURIComponent(key);
      val = val === undefined ? null : decodeURIComponent(val);

      if (!ret.hasOwnProperty(key)) {
        ret[key] = val;
      } else if (Array.isArray(ret[key])) {
        ret[key].push(val);
      } else {
        ret[key] = [ret[key], val];
      }

      return ret;
    }, {});
  }

  clearSession(): void {
    if (!this.haveAuthenticated) {
      return;
    }
    this.haveAuthenticated = false;
    this.popupWindow = window.open('https://clever.com/oauth/logout', 'Window',
      'width=100,height=100,location=0,status=0,menubar=0,titlebar=0');
    this.popupWindow.blur();
    window.focus();
    this.popupTimer = setInterval(() => {
      if (this.popupWindow.closed) {
        clearInterval(this.popupTimer);
      } else {
        this.popupWindow.close();
        clearInterval(this.popupTimer);
      }
    }, 5000);
  }
}
