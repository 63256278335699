/// <reference types="@angular/localize" />

import {bootstrapApplication} from '@angular/platform-browser';
import {appConfig} from './app/app.config';
import {AppComponent} from './app/app.component';
import {MsalRedirectComponent} from '@azure/msal-angular';
import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
  .then(appRef => appRef.bootstrap(MsalRedirectComponent))
  .catch((err) => console.error(err));
