import {Injectable} from '@angular/core';
import {CachePriority} from '../core/enums/cache-priority.enum';
import {ScreenSessionResponse} from '../models';
import {HttpAction} from '../core/enums/http-action.enum';
import {ApiClientService} from '../core/api-client.service';
import {PostResponse} from '../core/models/api-post-response';
import {ApiClientOptions} from '../core/models/api-client-options';

@Injectable({providedIn: 'root'})
export class ScreenSessionService {

    constructor(private apiClient: ApiClientService) {
    }

    getScreenSessions(userId: string, screenSessionId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Promise<ScreenSessionResponse>{
        let getURL = `api/Users/${userId}/ScreenSessions/${screenSessionId}`;
        return this.apiClient.InvokeApi<ScreenSessionResponse>(getURL, HttpAction.Get, new ApiClientOptions(true, cacheLevel)).toPromise();
    }

    postScreenSession(userId: string, postData: ScreenSessionResponse, cacheLevel: CachePriority = CachePriority.NetworkFirst): Promise<PostResponse> {
        let setURL = `api/Users/${userId}/ScreenSessions`;
        return this.apiClient.InvokeApi<PostResponse>(setURL, HttpAction.Post, postData, new ApiClientOptions(true, cacheLevel)).toPromise();
    }

    updateScreenSession(userId: string, screenSessionId: string, expirationTime, cacheLevel: CachePriority = CachePriority.NetworkFirst): Promise<PostResponse> {
        let setURL = `api/Users/${userId}/ScreenSessions/${screenSessionId}`;
        return this.apiClient.InvokeApi<PostResponse>(setURL, HttpAction.Put, {expirationTime: expirationTime}, new ApiClientOptions(true, cacheLevel)).toPromise();
    }
}
