import {makeEnvironmentProviders} from '@angular/core';
import {ngHttpCachingInterceptor} from './ng-http-caching-interceptor.service';
import {NG_HTTP_CACHING_CONFIG, NgHttpCachingConfig, NgHttpCachingService} from './ng-http-caching.service';

export function withNgHttpCachingInterceptor() {
  return ngHttpCachingInterceptor;
}
export function provideNgHttpCaching(ngHttpCachingConfig?: NgHttpCachingConfig) {
  return makeEnvironmentProviders([
    { provide: NG_HTTP_CACHING_CONFIG, useValue: ngHttpCachingConfig },
    NgHttpCachingService,
  ]);
}
