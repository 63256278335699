import {AppStateService} from '../../../../../../src/app/app.service';
import {ApiClientService} from '../../../../../../src/app/core/api-client.service';
import {ApiQueueService} from '../../../../../../src/app/core/api-queue.service';
import {ApiHttpService} from '../../../../../../src/app/core/api-http.service';
import {BookMetadataService} from '../../../../../../src/app/core/book-metadata.service';
import {ClassesService} from '../../../../../../src/app/api-client/classes.service';

export const legacyServices = [
  AppStateService,
  ApiClientService,
  ApiQueueService,
  ApiHttpService,
  BookMetadataService,
  ClassesService,
];
