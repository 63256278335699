import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppStateService} from '../app.service';
import {AppStateConstants} from '../globals';
import {GlobalConstants} from '../globals';
import { isNullOrUndefined } from 'app/shared/utils/global.utils';

@Injectable({
  providedIn: 'root'
})
export class ClasslinkService {

    popupWindow: Window;
    popupTimer;
    observer;
    redirectUrl: string;
    private haveAuthenticated = false;

    constructor(private _http: HttpClient,
                private _appStateService: AppStateService) {
    }

    authenticate(): Observable<string> {
        return new Observable(observer => {
            this.redirectUrl = window.location.origin + window.location.pathname + 'index.html?classlink';
            const url = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${encodeURIComponent(this.redirectUrl)}&client_id=${GlobalConstants.ClasslinkClient}&response_type=code&state=f3dhsde`;
            this.popupWindow = window.open(url, 'Window', 'width=500,height=600,location=0,status=0');

            this.popupTimer = setInterval(() => {
                if (this.popupWindow.closed) {
                    clearInterval(this.popupTimer);
                    observer.error('Classlink authentication failed.');
                } else {
                    let href: string;
                    try {
                        href = this.popupWindow.location.href;
                    } catch (e) {
                        // Don't do anything as accessing location from inter browser windows returns exception if the urls are different.
                    }
                    if (href != null) {
                        const re = /code=(.*)/;
                        const found = href.match(re);
                        if (found) {
                            this.haveAuthenticated = true;
                            clearInterval(this.popupTimer);
                            this.popupWindow.close();
                            const parsed = this.parse(href.substr(this.redirectUrl.length + 1));
                            if (!isNullOrUndefined(parsed.code)) {
                                this.observer = observer;
                                this.exchangeToken(parsed.code);
                            } else {
                                observer.error('Classlink authentication failed.');
                            }
                        }
                    }
                }
            }, 100);
        });
    }

    private exchangeToken(code) {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/json');
        const creds = {
            code: code,
            web: false
        };
        const url = 'api/ClassLinkAuth/OAuth/Tokens';
        this._http.post(this._appStateService.get(AppStateConstants.lightSailServerURL) + url, JSON.stringify(creds),
            {
                headers: headers,
                responseType: 'text'
            }).subscribe(data => {
            if (this.observer != null) {
                this.observer.next(data);
                this.observer.complete();
            }
        }, () => {
          // Retrying with lightsail web app if fails to get the token with first
            creds.web = true;
            this._http.post(this._appStateService.get(AppStateConstants.lightSailServerURL) + url, JSON.stringify(creds),
                {headers: headers, responseType: 'text'}).subscribe(data => {
                if (this.observer != null) {
                    this.observer.next(data);
                    this.observer.complete();
                }
            }, () => {
                if (this.observer != null) {
                    this.observer.error('ClassLink authentication failed.');
                }
            });
        });
    }

    exchangeTokenFromClassLink(classlinkPath: string): Observable<string> {
        return new Observable(observer => {
            this.haveAuthenticated = true;
            this.observer = observer;
            this.redirectUrl = window.location.origin + window.location.pathname + 'index.html?classlink';
            const index = classlinkPath.indexOf('?classlink');
            const parsed = this.parse(classlinkPath.substr(index + 10));
            if (!isNullOrUndefined(parsed.code)) {
                this.observer = observer;
                this.exchangeToken(parsed.code);
            } else {
                observer.error('Classlink authentication failed.');
            }
        });
    }

    private parse(str) {
        if (typeof str !== 'string') {
            return {};
        }

        str = str.trim().replace(/^(\?|#|&)/, '');

        if (!str) {
            return {};
        }

        return str.split('&').reduce(function (ret, param) {
            const parts = param.replace(/\+/g, ' ').split('=');
            let key = parts.shift();
            let val = parts.length > 0 ? parts.join('=') : undefined;

            key = decodeURIComponent(key);
            val = val === undefined ? null : decodeURIComponent(val);

            if (!ret.hasOwnProperty(key)) {
                ret[key] = val;
            } else if (Array.isArray(ret[key])) {
                ret[key].push(val);
            } else {
                ret[key] = [ret[key], val];
            }

            return ret;
        }, {});
    }

    clearSession(): void {
        if (!this.haveAuthenticated) {
            return;
        }
        this.haveAuthenticated = false;
        this.popupWindow = window.open('https://clever.com/oauth/logout', 'Window', 'width=100,height=100,location=0,status=0,menubar=0,titlebar=0');
        this.popupWindow.blur();
        window.focus();
        this.popupTimer = setInterval(() => {
            if (this.popupWindow.closed) {
                clearInterval(this.popupTimer);
            } else {
                this.popupWindow.close();
                clearInterval(this.popupTimer);
            }
        }, 5000);
    }
}
