import {ResolveFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectUserDetailsCallState} from '../../core/store/user/user.selectors';
import {UserActions} from '../../core/store/user/user.actions';
import {map} from 'rxjs/operators';
import {CallState} from '../../../../../consumer/src/app/models/enums/call-state';
import {filter} from 'rxjs';

export const clientResolver: ResolveFn<boolean> = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  // this will load data based on user type
  // this action would also be responsible to load user specific settings and configurations that will be required throughout the app
  store.dispatch(UserActions.setupUserData());
  return store.select(selectUserDetailsCallState).pipe(
    filter(callState => callState !== CallState.Initial && callState !== CallState.Loading),
    map(callState => {
      if (callState === CallState.Loaded) {
        return true;
      } else if (callState === CallState.Error) {
        router.navigate(['/login'])
      }
    })
  );
};
