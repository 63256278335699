import {MsalModule} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import {environment} from '../../../environments/environment';

// Microsoft SSO module initialization
export const msalInit = MsalModule.forRoot(new PublicClientApplication({
  auth: {
    clientId: environment.microsoftSSOClientId,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
  },
  cache: {
    //@ts-ignore
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
  },
}), {
  interactionType: InteractionType.Popup,
  authRequest: {
    scopes: ['user.read'],
  },
}, {
  interactionType: InteractionType.Popup,
  protectedResourceMap: new Map<'https://graph.microsoft.com/v1.0/me', [
    'user.read'
  ]>(),
});
