import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectAuthFlowState} from '../store/auth/auth.selectors';
import {map} from 'rxjs/operators';
import {filter} from 'rxjs';
import {CallState} from '../../../../../consumer/src/app/models/enums/call-state';

export const authGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(selectAuthFlowState).pipe(
    filter(state => state !== CallState.Loading),
    map(state => {
      if (state === CallState.Loaded) {
        return true;
      } else {
        // Redirect to the login page if the user is not authenticated
        router.navigate(['/login']);
        return false;
      }
    })
  );
};
