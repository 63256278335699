import {ActionReducer, MetaReducer} from '@ngrx/store';
import {AuthActions} from '../auth/auth.actions';

export function clearStateMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function clearStateFn(state, action) {
    if (action.type === AuthActions.logoutSuccess.type) {
      state = {};
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [clearStateMetaReducer];
