import {Store} from '@ngrx/store';
import {AppStateService} from '../../../../../../src/app/app.service';
import {SessionStorageService} from '../../../../../../src/app/localstorage/session-storage.service';
import {AppStateConstants, GlobalConstants} from '../../../../../../src/app/globals';
import {environment} from '../../../environments/environment';
import {AuthActions, UserSessionActions} from '../store/auth/auth.actions';
import {StringUtils} from '../../../../../../src/app/shared/utils/string.utils';

export function initializeApp(store: Store, appStateService: AppStateService) {
  return () => {
    // This is being used in V10 legacy code
    appStateService.set(AppStateConstants.lightSailServerURL, environment.lsApiUrl);

    // Hydrate user sessionId from session storage in case user reload browser and previous sessionId exists
    // @TODO: Handle the rehydrate case when user forced log out due to invalid token, in that case session end doesn't get called on re-login
    if (SessionStorageService.Get(GlobalConstants.UserSessionId)) {
      store.dispatch(UserSessionActions.hydrateUserSessionId({sessionId: SessionStorageService.Get(GlobalConstants.UserSessionId)}));
    }

    if (SessionStorageService.Get(GlobalConstants.IDSToken)) {
      // store.dispatch(AuthActions.authenticateSuccess({accessToken: SessionStorageService.Get(GlobalConstants.AuthKey)}))
      //@TODO: Rehydrate the LS and MP auth responses from localstorage or IDB to avoid calling authenticate again
      store.dispatch(AuthActions.authenticateLSMP({token: SessionStorageService.Get(GlobalConstants.IDSToken)}));
    } else {
      const identityServerToken = new URL(window.location.href).searchParams.get('it');
      if (identityServerToken) {
        try {
          const parsedToken = StringUtils.parseJwt(identityServerToken);
          if (parsedToken['customerId'] && parsedToken['username'] && parsedToken['password']) {
            store.dispatch(AuthActions.authenticate({
              customerId: parsedToken['customerId'],
              username: parsedToken['username'],
              password: parsedToken['password']
            }));
          } else {
            store.dispatch(AuthActions.authenticateLSMP({token: identityServerToken}));
          }
        } catch (e) {

        }
      }
    }

    return true;
  };
}
