import {inject, Injectable} from '@angular/core';
import {ENV_CONFIG} from '../../environment.config';

declare var gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  environmentConfig = inject(ENV_CONFIG);

  private googleAuth;

  constructor() { }

  async authenticate() {
    if (!this.googleAuth) {
      await new Promise((api_ready) => {
        document.head.append(
          Object.assign(
            document.createElement('script'),
            {
              src: 'https://apis.google.com/js/platform.js',
              onload: api_ready,
            },
          ),
        );
      });
      await new Promise(auth2_ready => gapi.load('auth2', auth2_ready));
      this.googleAuth = gapi.auth2.init({
        client_id: this.environmentConfig.googleClientId,
        cookie_policy: 'single_host_origin',
        scope: 'profile',
      });
    }
    const options = new gapi.auth2.SigninOptionsBuilder();
    options.setPrompt('select_account');
    const googleUser = await this.googleAuth.signIn(options);
    return googleUser.getAuthResponse()
  }
}
