import {provideState, provideStore} from '@ngrx/store';
import {authFeature} from './auth/auth.reducer';
import {provideEffects} from '@ngrx/effects';
import {AuthEffects} from './auth/auth.effects';
import {provideStoreDevtools} from '@ngrx/store-devtools';
import {isDevMode} from '@angular/core';
import {metaReducers} from './meta-reducers/meta-reducers';


// Global store imports ONLY
export const storeImports = [
  provideStore([], {metaReducers}),
  provideStoreDevtools({
    maxAge: 25, // Retains last 25 states
    logOnly: !isDevMode(), // Restrict extension to log-only mode
    autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
    traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
  }),
  provideState(authFeature),
  provideEffects(AuthEffects)
];
